





















































































































import { Inject } from '@cds/common';
import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { WechatImageTextService } from '@/services/wechat-imagetext-service';

@Component({
  components: {

  }
})
export default class wechatImageText extends Vue {
  public form: any = {
    pages: 1,
    size: 10,
    current: 1,
    total: 0,
    publish:'',
    history:''
  };
  public typeId:any[] = [];
  private tableData: any[] = [];
  public applications: any[] = [];
  public CreateTime: any[] = [];//创建时间
  public publicTime: any[] = [];//发布时间
  public kingList: any[] = [];//根据专栏获取分类
  public loading: Boolean = true;
  public kindshow: Boolean = false;
  private currentRow: any = null;
  private linkPre: string = '';

  @Inject(WechatImageTextService) private wechatImageTextService!: WechatImageTextService;

  public async created(): Promise<void> {
    await this.applicationsList();
    await this.getList();
  }

  //预览图文
  public async preview(row: any) {
    let param = {
      id:row.id
    }
    const res = await this.wechatImageTextService.articleToid(param); 
    if(res!= null){
      this.linkPre = `${location.origin}/historynew-wecom-front/?articleId=` + res + "&PC=1";
      // this.linkPre = 'https://dev.wecomfoundation.novartis.com.cn/historynew-wecom-front/?articleId=' + res + "&PC=1"
      window.open(this.linkPre);
    }  
  }

  public search() {
    this.form.current = 1;
    if( this.typeId !=undefined && this.typeId!=null ){
       let arr = this.typeId
       this.form.typeIds = arr.join(',');
    }   

    if (this.CreateTime && this.CreateTime.length > 0) {
      this.form.startTime = this.CreateTime[0];
      this.form.endTime = this.CreateTime[1];
    } else {
      this.form.startTime = "";
      this.form.endTime = "";
    }

    if (this.publicTime && this.publicTime.length > 0) {
      this.form.startPublishTime = this.publicTime[0];
      this.form.endPublishTime = this.publicTime[1];
    } else {
      this.form.startPublishTime = "";
      this.form.endPublishTime = "";
    }

    this.getList()
  }
  //分页相关
  public handleSizeChange(size: number) {
    this.form.size = size;
    this.getList();
  }
  public handleCurrentChange(current: number) {
    this.form.current = current;
    this.getList();
  }
  //获取数据源
  public async getList() {
    this.loading = true;
    const res = await this.wechatImageTextService.articleQuery(this.form);
    this.loading = false;
    let data: any = res.records
    this.formData(data)
    this.form.total = res.total;
    this.form.pages = res.pages;
    this.form.size = res.size;
    this.form.current = res.current;
  }
  //处理专栏id对应的name
  public async formData(res: any) {
    let data = res
    for (var item = 0; item < data.length; item++) {
      for (var i = 0; i < this.applications.length; i++) {
        if (this.applications[i].channelAgentId == data[item].agentId) {
          data[item].agentName = this.applications[i].name
        }
      }
    }
    this.tableData = data
  }
  //获取专栏
  public async applicationsList(): Promise<void> {
    const res = await this.wechatImageTextService.applicationsList();
    this.applications = res;
    for (var i = 0; i < this.applications.length; i++) {
      this.applications[i].channelAgentId = Number(this.applications[i].channelAgentId);
    }
  }
  // channelChange 选择专栏监听
  public async channelChange(val: any): Promise<void> {
    console.log('186', val)
    this.kingList = [];
    this.form.typeIds = '';
    if (val != '') {
      this.kindshow = true;
      this.getKindlist(val)
    } else {
      this.kindshow = false;
    }


  }
  //获取图文分类
  public async getKindlist(val: any) {
    var data_ = {
      agentId: val,
      size: 0,
      current: 0
    }
    const res = await this.wechatImageTextService.articleTypeQuery(data_);
    let data: any = res.records
    this.kingList = data;
    // this.form.typeId = data[0].id
  }
  //删除图文
  private async handDelete(row: any): Promise<void> {
    let param = {
      ids: row.id
    }
    try {
      await this.$confirm('确定要删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      });
      await this.wechatImageTextService.articleDelete(param);
      this.getList()
      this.$message({
        message: '删除成功',
        type: 'success'
      });
    } catch (e) {
      this.$message({
        type: 'info',
        message: '已取消删除'
      });
    }
  }
  //修改
  public handUpdate(row: any) {
    localStorage.setItem('imageTextType', row.id)
    this.$router.push("/addImageText");
  }
  //图文分类
  public imageTextTypeData() {
    this.$router.push("/imageTextClassify");
  }
  //创建图文
  public createImageText() {
    localStorage.setItem('imageTextType', '1')
    this.$router.push("/addImageText");
  }
  // 重置
  public reset() {
    var that = this;
    that.form = {
      typeId: '',
    }; 
    that.typeId = [];
    that.CreateTime = [];
    that.publicTime = [];
    this.getList()
  }
  private mounted() {
    document.onkeydown = (e) => {
      let key = (window.event as any).keyCode;
      if (key == 13) {
        this.search()
      }
    }
  }
}
